const dic = {
    "Chapter": "Глава",
    "Psalm": "Псалом",
    "Ukrainian": "Украинский",
    "Russian": "Русский",
    "English": "Английский",
    "Compare": "Сравнение",
    "Gallery": "Галерея",
    "History of reading": "История чтения",
    "Bible lessons": "Библейские уроки",
    "Bible courses": "Біблійні курсы",
    "Courses": "Курсы",
    "Registration": "Регистрация",
    "Exit": "Выход",
    "Sign in": "Вход",
    "Settings": "Настройки",
    "Search": "Поиск",
    "Parallel places": "Параллельные места",
    "Numbering of verses": "Нумерация стихов",
    "Footnotes": "Сноски",
    "Translation": "Перевод",
    "Books": "Книги",
    "Select a section": "Выбрать раздел",
    "Search with register": "Поиск с учетом регистра",
    "Search whole word": "Искать целое слово (Как введено)",
    "New testament": "Новый завет",
    "Old testament": "Ветхий завет",
    "The whole Bible": "Вся Библия",
    "Home": "Главная",
    "Enter the text": "Введите текст",
    "To find": "Найти",
    "Search taking into account the register": "Поиск с учетом регистра",
    "Search for a whole word (as entered)": "Поиск целого слова (как введено)",
    "In Ukrainian": "На украинском",
    "In Russian": "На русском",
    "In English": "На английском",
    "Greetings": "Приветствия",
    "Birthday": "С Днём рождения",
    "Inspirations": "Вдохновения",
    "Quotes": "Цитаты",
    "Psalms": "Псалмы",
    "Saturday": "Суббота",
    "Wedding": "Свадьба",
    "Small": "Маленький",
    "Medium": "Средний",
    "Large": "Большой",
    "Select": "Выбрать",
    "Copy": "Копировать",
    "Share": "Поделиться",
    "Share viber": "Поделиться в Viber",
    "Share telegram": "Поделиться в Telegram",
    "Share whatsapp": "Поделиться в WhatsApp",
    "Selected": "Выбрано",
    "Strong's Dictionary": "Словарь Стронга",
    "Pronunciation": "Произношение",
    "Transliteration": "Транслитерация",
    "Empty list": "Пустой список",
    "End of the book": "Конец книги",
    "Plan": "План",
    "Morning reading": "Утренние чтения",
    "Bible": "Библия",
    "Read": "Читать",
    "Bible reading plan": "План чтения Библии",
    "Select translation": "Выберите перевод",
    "Reading for today": "Чтение на сегодня",
    "More details": "Детальнее",
    "Select book": "Выбрать книгу",
    "More pictures": "Больше картинок",
    "Copied": "Скопировано",
    "Bible reading plans": "Планы чтения Библии",
    "Start a plan": "Читать этот план",
    "Read the plan": "Читать этот план",
    "Complete the plan": "Завершить план",
    "Day": "День",
    "Do you really want to delete your reading progress": "Вы действительно хотите удалить прогресс чтения",
    "Yes": "Да",
    "Bible School": "Школа Библии",
    "No": "Нет",
    "Recited": "Прочитано",
    "Gospels and Epistles": "Евангелия и послания",
    "The Pentateuch and the history of Israel": "Пятикнижие и история Израиля",
    "Psalms and wisdom literature": "Псалмы и литература мудрости",
    "Chronicles and prophets": "Хроники и пророки",
    "Chronological Bible": "Хронологическая Библия",
    "Happy Birthday": "С Днём рождения",
    "Happy Baptism Day": "С Днём крещения",
    "With the birth of a daughter": "С рождением дочери",
    "With the birth of a son": "С рождением сына",
    "Happy New Year": "С Новым Годом",
    "To the best grandmother": "Поздравление бабушки",
    "The best mom": "Поздравление маме",
    "The best father": "Поздравление отца",
    "The best grandfather": "Поздравление дедушки",
    "Biblical": "Библейские",
    "Greetings tab": "Приветственные",
    "Paul\'s message": "Послание Павла",
    "Jacob, Peter, Ivan": "Иакова, Петра, Ивана",
    "Gospels": "Евангелии",
    "Pentateuch": "Пятикнижие",
    "Proverbs, Ecclesiastes": "Притчи, Экклезиаста",
    "Great prophets": "Великие пророки",
    "Isaiah": "Исайя",
    "Jeremiah": "Иеремия",
    "Ezekiel": "Иезекииля",
    "Minor prophets": "Малые пророки",
    "Daniel": "Даниила",
    "Revelation": "Откровение",
    "Acts of the Apostles": "Действия апостолов",
    "Thematic": "Тематические",
    "Good morning": "Доброе утро",
    "Good evening": "Добрый вечер",
    "Good night": "Доброй ночи"
}

export default dic;